const mainCategory = [
  '스웨디시',
  '타이마사지',
  '중국마사지',
  '한국마사지',
  '스포츠마사지',
  '아로마-마사지',
  '왁싱',
  '에스테틱',
]

const allCategory = [
  '스웨디시',
  '타이마사지',
  '중국마사지',
  '한국마사지',
  '스포츠마사지',
  '아로마-마사지',
  '왁싱',
  '에스테틱',
  '1인샵',
//   '1인샵-스웨디시',
//   '1인샵-왁싱',
  '홈케어·홈타이',
  '로미로미',
  '24시간-마사지',
  '수면가능-마사지',
//   '1인샵-에스테틱',
  '프랜차이즈',
  // '마사지'
]

export {
  mainCategory,
  allCategory
}
